<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item v-bind="$attrs" :to="to" exact @click="$emit('click')">
      <v-list-item-avatar
        :color="colors[item.currentStateColor] || colors['default']"
      >
        <v-img
          :src="getObjectImageUrlOrDefault(item.currentStateIcon)"
          width="30"
          height="30"
          max-width="30"
          max-height="30"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex align-center">
          <div class="mr-2" :class="{ 'red--text': !item.enabled }">
            {{ item.enabled ? 'Enabled' : 'Disabled' }}
          </div>
          <div class="d-flex mr-2">
            <v-icon
              v-if="batteryIcon"
              small
              :color="batteryIcon.color"
              v-text="batteryIcon.icon"
            />
            <div v-if="showBatteryLevel">
              {{ item.statusesBatteryLevel + '%' }}
            </div>
          </div>
          <v-icon
            v-if="activityIcon"
            :color="activityIcon.color"
            class="mr-2"
            small
            v-text="activityIcon.icon"
          />
          <v-icon
            v-if="alarmIcon"
            :color="alarmIcon.color"
            class="mr-2"
            small
            v-text="alarmIcon.icon"
          />
          <v-icon
            v-if="dataIcon"
            :color="dataIcon.color"
            class="mr-2"
            small
            v-text="dataIcon.icon"
          />
          <v-icon
            v-if="gpsIcon"
            :color="gpsIcon.color"
            class="mr-2"
            small
            v-text="gpsIcon.icon"
          />
          <v-icon
            v-if="item.statusesEmulation"
            class="mr-2"
            small
            v-text="'$emulation'"
          />
          <v-icon v-if="item.muted" class="mr-2" small v-text="'$mute'" />
          <v-icon
            v-if="item.favourite"
            class="mr-2"
            small
            v-text="'$star_on'"
          />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="actions.length > 0 && hover">
        <dropdown-menu :items="actions" item-key-text="name">
          <template #activator="{on}">
            <v-btn icon @click.stop.prevent="on.click" @mousedown.stop="">
              <v-icon v-text="'$more_vert'" />
            </v-btn>
          </template>
        </dropdown-menu>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { firstLetters } from '@/utils';
import { useObjectStatus } from '@/modules/objects/compositions/objectStatus';
import { toRefs } from '@vue/composition-api';
import { getObjectImageUrlOrDefault } from '@/provider/utils';
import { colors } from '@/compositions/map/utils/data';

export default {
  name: 'ObjectListItem',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Array,
      default: () => []
    },
    to: {
      type: [Object, String],
      default: ''
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const objectStatus = useObjectStatus(item);
    return {
      firstLetters,
      ...objectStatus,
      getObjectImageUrlOrDefault,
      colors
    };
  }
};
</script>

<style></style>
